import * as React from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import PartnerForm from "../components/PartnerForm";
import Main from "../common/Main";

export default function Parceiro() {
  return (
    <Main>
      <Navbar backgroundColor="#e3e3e3" display="none"></Navbar>
      <PartnerForm />
      <Footer />
    </Main>
  );
}
