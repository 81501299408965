import React from "react";
import InputMask from "react-input-mask";

export default function PartnerForm() {
  return (
    <div className="partner-form">
      <div className="partner-form__texto">
        <h2>Torne-se um parceiro e aumente a visibilidade do seu negócio!</h2>
      </div>
      <form
        className="partner-form__Form"
        name="partner"
        method="POST"
        data-netlify="true"
        action="/obrigado"
        subject="Parceria pelo formulário do site ifrsys.com.br"
      >
        <p>Preencha os seus dados e venha se tornar um PARCEIRO!</p>
        <input name="form-name" type="hidden" value="partnership" />
        <input name="nome" placeholder="Nome"></input>
        <InputMask
          name="telefone"
          placeholder="Telefone"
          mask="(99) 99999-9999"
        />
        <input name="email" placeholder="E-mail"></input>
        <input name="cidade-uf" placeholder="Cidade-UF"></input>
        <input name="empresa-segmento" placeholder="Empresa/Segmento"></input>
        <button type="submit">Fale com um consultor</button>
      </form>
    </div>
  );
}
